<template>
  <div class="container intake-categories-container">
    <div class="row">
      <div class="col-12">
        <router-view class="mt-3"></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "taskTypes",
  mounted() {
    this.getTaskTypes();
  },
  methods: {
    ...mapActions({
      getTaskTypes: "task/getTaskTypes",
    }),
  },
};
</script>
